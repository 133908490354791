<template>
  <div>
    <v-card>
      <v-card flat>
        <v-card-text>
          <div v-if="loading" class="loading_custom">
            <v-progress-circular :size="50" color="primary" indeterminate />
          </div>
          <form class="form_datos_personales">
            <v-layout wrap>
              <v-flex sm6 lg6 xs6>
                <v-text-field
                  v-model="customValue.numVideoPerSlot"
                  type="text"
                  class="mr-2"
                  :label="$t('videoconsulta.num_videos_por_slot')"
                  append-icon="mdi-plus-box-multiple"
                />
              </v-flex>
              <v-flex sm6 lg6 xs6>
                <v-text-field
                  v-model="customValue.timeMinProxVideo"
                  type="text"
                  class="mr-2"
                  :label="$t('videoconsulta.tiempo_min_prox_video') + ' (min)'"
                  append-icon="mdi-av-timer"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="newPhone"
                  type="text"
                  class="mr-2"
                  :label="$t('videoconsulta.tlf_noty')"
                  append-icon="mdi-phone"
                  :placeholder="$t('videoconsulta.tlf_noty_placeholder')"
                  @keyup="insertNewPhone"
                />
              </v-flex>
              <v-flex xs12 class="phoneChips">
                <span v-for="(item, index) in customValue.notificationPhonesFront" :key="index">
                  <v-chip close class="chip--select-multi" color="blue lighten-4" @click:close="removePhone(item)">
                    {{ item }}
                  </v-chip>
                </span>
              </v-flex>
            </v-layout>
            <div class="text-center text-sm-right">
              <v-btn class="white--text" :disabled="disabledButton" color="blue" @click="editCustom">{{
                $t('common.save')
              }}</v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        top="top"
        :multi-line="snackbar.mode === 'multi-line'"
        :vertical="snackbar.mode === 'vertical'"
      >
        <v-icon v-if="snackbar.icon !== ''">{{ snackbar.icon }}</v-icon
        >&nbsp;{{ snackbar.text }}
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import { getCustomValuesUrl, editCustomValuesUrl, getHeader } from '../../config/config';

export default {
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    disabledButton: true,
    snackbar: {
      text: '',
      icon: '',
      color: 'info',
      show: false,
      mode: '',
      timeout: 5000,
    },
    newPhone: '',
    customValue: {},
  }),

  created() {
    this.$http.post(getCustomValuesUrl, { user_id: this.authUser.id }, { headers: getHeader() }).then(response => {
      if (response.status === 200 && response.data && response.data.status === 1) {
        this.customValue = response.data.customValue;
        this.loading = false;
        this.disabledButton = false;
      } else {
        this.$router.push('/dashboard');
      }
    });
  },

  methods: {
    insertNewPhone: function(e) {
      if (e.keyCode === 13) {
        let phone = this.newPhone.replace(' ', '');
        phone = phone.replace('-', '');
        if (this.validatePhone(phone)) {
          this.newPhone = '';
          this.customValue.notificationPhonesFront.push(phone);
        } else {
          this.showSnackbar(this.$t('errors.format_phone'), 'warning', '', 3000);
        }
      }
    },

    validatePhone(phone) {
      const phoneno = /^\+([0-9]{2})\)?([0-9]{9})$/;
      return phoneno.exec(phone);
    },

    showSnackbar(text, color, mode, time) {
      this.snackbar.color = color;
      this.snackbar.text = text;
      this.snackbar.mode = mode;
      this.snackbar.time = time;
      this.snackbar.show = true;
    },

    editCustom() {
      if (!this.disabledButton) {
        this.disabledButton = true;
        this.$http
          .post(
            editCustomValuesUrl,
            { user_id: this.authUser.id, customValue: this.customValue },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data && response.data.status === 1) {
              this.showSnackbar(this.$t('success.save_common'), 'success', 'mdi-check', '', 3000);
            } else {
              this.showSnackbar(this.$t('errors.try_again'), 'warning', 'warning', '', 3000);
            }
            this.disabledButton = false;
          });
      }
    },

    removePhone(item) {
      this.customValue.notificationPhonesFront.splice(this.customValue.notificationPhonesFront.indexOf(item), 1);
      this.customValue.notificationPhonesFront = [...this.customValue.notificationPhonesFront];
    },
  },
};
</script>
<style scoped lang="css">
.loading_custom {
  margin-top: 30px;
  text-align: center;
}
.form_datos_personales {
  padding: 10px;
}
.phoneChips {
  margin-top: -20px;
  margin-left: -5px;
}
</style>
